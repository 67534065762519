import React, {useState, useContext, useCallback} from 'react';
import { Button } from '../../Atoms/Buttons/Button';
import { TextTitles } from '../../Atoms/Texts/Titles';
import { TextTitle24 } from '../../Atoms/Texts/Title24';
import { Line } from '../../Atoms/Lines/Line';
import { Text16 } from '../../Atoms/Texts/Text16';
import s from "./Step8.module.scss";
import { PopupWithText } from '../../Atoms/Popup/PopupWithText';
import {useTranslation} from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserContext from "../../../context";
import { PreloaderWithText } from '../../Atoms/Preloader/PreloaderWithText';



export const Step8 = () => {
    const {t} = useTranslation('common');
    const { id } = useContext(UserContext);
    const [percent, setPercent] = useState();
    const [box1, setBox1] = useState();
    const [box2, setBox2] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isPopup, setIsPopup] = useState(false);
    const [isPopup2, setIsPopup2] = useState(false);

    const handleSubmit = useCallback(() => {
        setIsLoading(true)
    },[setIsLoading]);

    const openPopup = useCallback(() => {
        setIsPopup(true)
    },[setIsPopup]);

    const openPopup2 = useCallback(() => {
        setIsPopup2(true)
    },[setIsPopup2]);

    const onClose = useCallback(() => {
        setIsPopup(false)
    },[setIsPopup]);

    const onSubmit = useCallback(() => {
        setIsPopup(false)
        setBox1(true)
    },[setBox1,setIsPopup ]);

    const onClose2 = useCallback(() => {
        setIsPopup2(false)
    },[setIsPopup2]);

    const onSubmit2 = useCallback(() => {
        setIsPopup2(false)
        setBox2(true)
    },[setBox2, setIsPopup2]);
    

    return (
        <div className={s.mainComponentBackground}>
            {isLoading && <PreloaderWithText text="Lorem Ipsum has been make a the industry's standard dummy text ever since the 1500s, took a galley of type and scrambled it to make a type specimen book." />}
            {isPopup && <PopupWithText 
                            onSubmit={onSubmit} 
                            onClose={onClose}
                            title="Ознакомилось с документом" 
                            text="Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.
                            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.
                            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                            
                            
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                            
                            
                            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla." 
                            />}
            {isPopup2 && <PopupWithText 
                            onSubmit={onSubmit2} 
                            onClose={onClose2}
                            title="Ознакомилось с документом" 
                            text="Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.
                            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.
                            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                            
                            
                            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
                            
                            
                            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla. Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla." 
                            />}
            <ToastContainer />
            <div className={s.mainComponentBackgroundImage}>
                <div className={s.mainComponent}>
                    <div className={s.formBg}>
                        <div className={s.form}>
                            <TextTitles 
                                text={t('step8.title')}
                            />
                            <Line marginBottom={0} />
                            <div className={s.lineTextWrapper}>
                                <div className={s.lineText}>
                                    <div className={s.lineTextTitle}>
                                    {t('step8.interestRate')}
                                    </div>
                                    <div className={s.lineTextText}>
                                    24.6%
                                    </div>
                                </div>
                                <Line marginBottom={0} />
                                <div className={s.lineText}>
                                    <div className={s.lineTextTitle}>
                                    {t('step8.IRead')}
                                    </div>
                                    <div className={s.lineTextText}>
                                     <div className={s.lineInputBox}><input value={percent} onChange={({target}) => {setPercent(target.value)}} type="text" /> <span>%</span></div>
                                    </div>
                                </div>
                            </div>

                            <div className={s.ruleWrapper}>
                                <div className={s.ruleBox}>
                                    <TextTitle24 text={t('step8.box1Title')} />
                                    <Text16 text={t('step8.box1Text')} />
                                    <div className={s.chackboxWrapper}>
                                        <label className={s.formChackbox}>
                                            <input checked={box1} type="checkbox" onChange={({target}) => {setBox1(target.checked)}} /> 
                                        </label>
                                        <div onClick={openPopup} className={s.chackboxText}>{t('form.acquaintedWithTheDocument')}</div>
                                    </div>
                                </div>
                                <div className={s.ruleBox}>
                                    <TextTitle24 text={t('step8.box2Title')} />
                                    <Text16 text={t('step8.box2Text')} />
                                    <div className={s.chackboxWrapper}>
                                        <label className={s.formChackbox}>
                                            <input checked={box2} type="checkbox" onChange={({target}) => {setBox2(target.checked)}} /> 
                                        </label>
                                        <div onClick={openPopup2} className={s.chackboxText}>{t('form.acquaintedWithTheDocument')}</div>
                                    </div>
                                </div>
                            </div>
                           
                            <Button
                                onClick={handleSubmit}  
                                value={t('button.checkout')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
