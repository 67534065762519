import React from "react";
import s from './Switcher.module.scss'

export const SwitcherInput = ({onChange = () => {}, label="", dark=true }) => {
    return <div className={`${dark? s.darkSwitch : s.lightSwitch} ${s.switchWrapper}`}>
                <div className={s.label}>{label}</div>
                <label className={`${s.switch}`}>
                    <input onChange={onChange} type="checkbox" />
                    <span className={`${s.slider} ${s.round}`}></span>
                </label>
            </div>
   
}