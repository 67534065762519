import React, { useEffect, useContext, useState, useCallback } from 'react';
import { Button } from '../../Atoms/Buttons/Button';
import { TextInput } from '../../Atoms/Inputs/Text';
import { TextareaInput } from '../../Atoms/Inputs/Textarea';
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel';
import { CheckboxInput } from '../../Atoms/Inputs/Checkbox';
import { TextTitles } from '../../Atoms/Texts/Titles';
import { TextTitle24 } from '../../Atoms/Texts/Title24';
import { Line } from '../../Atoms/Lines/Line';
import { SwitcherInput } from '../../Atoms/Inputs/Switcher';
import { ChooseFileInput } from '../../Atoms/Inputs/ChooseFile';
import { useTranslation } from "react-i18next";
import s from "./Step5.module.scss";
import UserContext from "../../../context";
import { Api } from '../../../Services/Api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";

const options1 = [
    { Name: 'xxxxxxxxxx', Value: 'xxxxxxxxxx' },
    { Name: 'Loan Type2', Value: '2' },
    { Name: 'Loan Type3', Value: '3' },
    { Name: 'Loan Type4', Value: '4' },
]

export const Step5 = () => {
    let history = useHistory();
    const {t} = useTranslation('common');
    const { id, setEmailData } = useContext(UserContext);
    const [ passport, setPassport ] = useState();
    const [ country, setCountry ] = useState();
    const [ city, setCity ] = useState();
    const [ region, setRegion ] = useState();
    const [ street, setStreet ] = useState();
    const [ home, setHome ] = useState();
    const [ flat, setFlat ] = useState();

    const [ customCountry, setCustomCountry ] = useState();
    const [ customCity, setCustomCity ] = useState();
    const [ customRegion, setCustomRegion ] = useState();
    const [ customStreet, setCustomStreet ] = useState();
    const [ customHome, setCustomHome ] = useState();
    const [ customFlat, setCustomFlat ] = useState();

    const [ show, setShow ] = useState(false);
    const [ show2, setShow2 ] = useState(false);

    const [ income, setIncome ] = useState();
    const [ placeOfWork, setPlaceOfWork ] = useState();
    const [ other, setOther ] = useState();
    const [ chooseFile, setChooseFile ] = useState();

    const [ email, setEmail ] = useState();
    const [ bankContactMethod, setBankContactMethod ] = useState();
    const [ statementSubmissionMethod, setStatementSubmissionMethod ] = useState();

    const [ iConfirmACRA, setIConfirmACRA ] = useState();
    const [ iConfirmTheLoan, setIConfirmTheLoan ] = useState();
    
    
    const handleSubmit = useCallback(() => {
        Api().put('applicationData', {
            hasSameAddress: !show,
            email: email,
            isCivilServant: show2,
            jobInfo: {
                amount: income,
                workplaceName: placeOfWork,
                other: other,
                file: chooseFile,
            },
            id
        }).then((a) => {
            setEmailData(email);
            history.push("/step-6");
        }).catch((a) => {
            toast.error(a);
        }).finally()
    }, [id, passport, country, city, region, street, home, flat, customCountry, customCity, customRegion, customStreet, customHome, customFlat, show, show2, income, placeOfWork, other, chooseFile, email, bankContactMethod, statementSubmissionMethod, iConfirmACRA, iConfirmTheLoan])

    useEffect(() => {
        Api().get(`passportData?id=${id}`).then((res) => {
            console.log(res)
            setPassport(res.passport_code);
            // setCountry()
            setCity(res.addressInfo.Region);
            setRegion(res.addressInfo.Community);
            setStreet(res.addressInfo.Street);
            setHome(res.addressInfo.Building);
            setFlat(res.addressInfo.Apartment);
        }).catch((a)=>{
            toast.error(a);
        }).finally()
    }, [id])

    return (
        <div className={s.mainComponentBackground}>
            <ToastContainer />
            <div className={s.mainComponentBackgroundImage}>
                <div className={s.mainComponent}>
                    <div className={s.formBg}>
                        <div className={s.form}>
                            <TextTitles text={t('step5.title')} />
                            <div className={s.inputWrapper}>
                                <TextInput
                                    onChange={({ target }) => { setPassport(target.value) }}
                                    placeholder={t('form.passportPlaceholder')}
                                    label={t('form.passport')}
                                    value={passport}
                                />
                                <div className={s.emptyBlock} ></div>
                            </div>
                            <Line marginBottom={36} />
                            <TextTitle24 text={t('step5.address')} />
                            <div className={s.inputWrapper}>
                                <TextInput 
                                    onChange={({target}) => {setCountry(target.value)}} 
                                    placeholder={t('form.countryPlaceholder')}
                                    label={t('form.country')}
                                    value={country}
                                />
                                <TextInput 
                                    onChange={({target}) => {setCity(target.value)}} 
                                    placeholder={t('form.cityPlaceholder')}
                                    label={t('form.city')}
                                    value={city}
                                />
                            </div>
                            <div className={s.inputWrapper}>
                                <TextInput 
                                    onChange={({target}) => {setRegion(target.value)}} 
                                    label={t('form.region')}
                                    placeholder={t('form.regionPlaceholder')}
                                    value={region}
                                />
                                <TextInput 
                                    onChange={({target}) => {setStreet(target.value)}} 
                                    label={t('form.street')}
                                    placeholder={t('form.streetPlaceholder')}
                                    value={street}
                                />
                            </div>
                            <div className={s.inputWrapper}>
                                <TextInput 
                                    onChange={({target}) => {setHome(target.value)}} 
                                    placeholder={t('form.homePlaceholder')}
                                    label={t('form.home')}
                                    value={home}
                                />
                                <TextInput 
                                    onChange={({target}) => {setFlat(target.value)}} 
                                    label={t('form.flat')}
                                    placeholder={t('form.flatPlaceholder')} 
                                    value={flat}
                                />
                            </div>
                            <Line />
                            <SwitcherInput label={t('step5.registrationAddress')} onChange={({target}) => {setShow(target.checked)}} />
                            {show && <>
                            <div className={s.inputWrapper}>
                                <SelectWithLabelInput
                                    onChange={({target}) => { setCustomCountry(target.value) }}
                                    options={options1}
                                    label={t('form.country')}
                                />
                                <SelectWithLabelInput
                                    onChange={({target}) => { setCustomCity(target.value) }}
                                    options={options1}
                                    label={t('form.city')}
                                />
                            </div>
                            <div className={s.inputWrapper}>
                                <SelectWithLabelInput
                                    onChange={({target}) => { setCustomRegion(target.value) }}
                                    options={options1}
                                    label={t('form.region')}
                                />
                                <TextInput
                                    onChange={({target}) => { setCustomStreet(target.value) }}
                                    label={t('form.street')}
                                    placeholder={t('form.streetPlaceholder')}
                                    value={customStreet}
                                />
                            </div>
                            <div className={s.inputWrapper}>
                                <TextInput
                                    onChange={({target}) => { setCustomHome(target.value) }}
                                    placeholder={t('form.homePlaceholder')}
                                    label={t('form.home')}
                                    value={customHome}
                                />
                                <TextInput
                                    onChange={({target}) => { setCustomFlat(target.value) }}
                                    label={t('form.flat')}
                                    placeholder={t('form.flatPlaceholder')}
                                    value={customFlat}
                                />
                            </div>
                            </>}
                            <Line />
                            <SwitcherInput label={t('step5.lawEnforcementOfficer')} onChange={({target}) => {setShow2(target.checked)}} />
                            {show2 &&<>
                            <div className={s.inputWrapper}>
                                <TextInput
                                    onChange={({target}) => { setIncome(target.value) }}
                                    placeholder={t('form.incomePlaceholder')}
                                    label={t('form.income')}
                                    value={income}
                                />
                                <TextInput
                                    onChange={({target}) => { setPlaceOfWork(target.value) }}
                                    label={t('form.placeOfWork')}
                                    placeholder={t('form.placeOfWorkPlaceholder')}
                                    value={placeOfWork}
                                />
                            </div>
                            <div className={s.inputWrapper}>
                                <TextareaInput
                                    onChange={({target}) => { setOther(target.value) }}
                                    label={t('form.other')}
                                    placeholder={t('form.otherPlaceholder')}
                                    value={other}
                                />
                                <ChooseFileInput onChange={({target}) => { setChooseFile(target.value) }} />
                            </div>
                            </>}
                            <Line />
                            <div className={s.inputWrapper}>
                                <TextInput
                                    onChange={({target}) => { setEmail(target.value) }}
                                    placeholder={t('form.emailPlaceholder')}
                                    label={t('form.email')}
                                    value={email}
                                />
                                <div className={s.emptyBlock}></div>
                            </div>
                            <div className={s.inputWrapper}>
                                <SelectWithLabelInput
                                    onChange={({target}) => { setBankContactMethod(target.value) }}
                                    options={options1}
                                    label={t('form.bankContactMethod')}
                                />
                                <SelectWithLabelInput
                                    onChange={({target}) => { setStatementSubmissionMethod(target.value) }}
                                    options={options1}
                                    label={t('form.statementSubmissionMethod')}
                                />
                            </div>
                            <CheckboxInput
                                onChange={({target}) => { setIConfirmACRA(target.checked) }}
                                label={t('step5.IConfirmACRA')}
                            />
                            <CheckboxInput
                                onChange={({target}) => { setIConfirmTheLoan(target.checked) }}
                                label={t('step5.IConfirmTheLoan')}
                            />
                            <Button
                                onClick={handleSubmit}
                                value={t('button.buttonName')}
                                disabled={!(email && iConfirmACRA && iConfirmTheLoan)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
