import React, { useState, useContext, useCallback, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ButtonSmall } from '../../Atoms/Buttons/ButtonSmall';
import s from "./Step9.module.scss";
import { useTranslation } from "react-i18next";
import camer from "./camera.png";
import { Api } from '../../../Services/Api'
import UserContext from "../../../context"
import { useHistory } from "react-router-dom";
import QRCode from "react-qr-code";

export const Step9 = () => {
    let history = useHistory();
    const { t } = useTranslation('common');
    const [activeCamera, setActiveCamera] = useState(false);
    const [showPicture, setShowPicture] = useState(false);
    const { id, setCurrentData } = useContext(UserContext);
    const [picture64, setPicture64] = useState('');
    const [width, setWidth]   = useState(window.innerWidth);
    const [isMobile, setIsMobile]   = useState(false);

    const queryParams = new URLSearchParams(window.location.search);
    const url = window.location.origin + window.location.pathname;
    const urlId = queryParams.get("id");

    useEffect(() => {
        if (urlId) {
            setCurrentData(urlId);
        }
        updateDimensions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const startCamer = useCallback(async() => {
        if(!urlId && !isMobile) {
            setActiveCamera(true);
            let stream = await navigator.mediaDevices.getUserMedia({ video: { width: 1280, height: 720 }, audio: false });
            document.querySelector("#video").srcObject = stream;
        }
    }, [urlId, isMobile])

    const clickPhoto = useCallback(() => {
        if(urlId || isMobile) {
            Api().post('uploadUserImage', {
                id,
                image: picture64
            }).then(() => {
                toast.success("Go to desktop for verification");
            }).catch((a) => {
                toast.error(a);
            }).finally()
        } else {
            setShowPicture(true)
            let canvas = document.querySelector("#canvas");
            canvas.width = 1280
            canvas.height = 720
            let video = document.querySelector("#video");
            canvas.getContext('2d').drawImage(video, 0, 0, 1280, 720, 0, 0, 1280, 720);
            let image_data_url = canvas.toDataURL('image/jpeg');

            // data url of the image
            Api().post('uploadUserImage', {
                id,
                image: image_data_url.replace('data:image/jpeg;base64,', '')
            }).then(() => {
                (urlId && isMobile) && toast.success("Go to desktop for verification");
            }).catch((a) => {
                toast.error(a);
            }).finally()
        }
    }, [id, urlId, picture64, isMobile])

    const handleMobilePhone = useCallback(() => {
        Api().post('verifyUserImage', {
            id,
        }).then((a) => {
            history.push("/step-5");
        }).catch((a) => {
            toast.error(a);
        }).finally()
    }, [id]);

    const uploadFileToServer = useCallback((event) => {
        let file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsBinaryString(file);
    
        reader.onload = function() {
            console.log(btoa(reader.result));
            setPicture64(btoa(reader.result));
        };
        reader.onerror = function() {
            console.log('there are some problems');
        };
    }, []);


    const updateDimensions = () => {
        setWidth(window.innerWidth);
        if(window.innerWidth <= 767) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    console.log("ismobile", isMobile)

    return (
        <div className={s.mainComponentBackground}>
            
            <ToastContainer />
            <div className={s.mainComponentBackgroundImage}>
                <div className={s.mainComponent}>
                    <div className={s.paper}>
                     <div className={s.cameraBox}>
                            <div className={s.cameraBoxTitle}>
                                {t('step9.allowToUseYourCamera')}
                            </div>
                            {activeCamera ? <video className={s.video} id="video" width="100%" autoPlay></video> :
                                <div onClick={startCamer} className={s.cameraBoxWrapper}>
                                    {(urlId || isMobile) && <input onChange={uploadFileToServer} className={s.chooseImage} type="file" accept="image/*" capture="user" />}
                                    <div className={s.cameraBoxImage}>
                                        <img alt="image" src={camer} />
                                    </div>
                                    <div className={s.cameraBoxText}>{t('step9.getCameraAccess')}</div>
                                </div>
                            }
                            <canvas className={`${s.canvas} ${showPicture ? s.showPicture : ''} `} id="canvas" width="100%" ></canvas>
                            <ButtonSmall onClick={clickPhoto} value={t('button.takeAPhoto')} />
                        </div>
                        
                            <div className={s.qrBox}>
                            {(!urlId || isMobile) &&
                                <>
                                    <div className={s.qrBoxTitle}>
                                        {t('step9.allowToUseYourQr')}
                                    </div>
                                    <div className={s.qrBoxWrapper}>
                                        <QRCode size={445} value={`${url}?id=${id}`} />
                                    </div>
                                </>
                                 }
                                <ButtonSmall onClick={handleMobilePhone} value={t('button.verifyPhoto')} />
                            </div>
                            
                       
                    </div>
                </div>
            </div>
        </div>
    )
}
