import React from 'react';
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import { Login } from './Components/Pages/Login';
import { Step2 } from './Components/Pages/Step2';
import { Step3 } from './Components/Pages/Step3';
import { Step4 } from './Components/Pages/Step4';
import { Step5 } from './Components/Pages/Step5';
import { Step6 } from './Components/Pages/Step6';
import { Step7 } from './Components/Pages/Step7';
import { Step8 } from './Components/Pages/Step8';
import { Step9 } from './Components/Pages/Step9';
import { Step10 } from './Components/Pages/Step10';

import { UserProvider } from './context';

import './App.css';

function App() {
  return (
    <UserProvider value={{}}>
      <BrowserRouter>
        <div className="App">
          <Switch>
            <Route exact path='/' render={() => <Redirect to={"/main"} />} />

            <Route path='/main' render={() => <Login />} />

            <Route path='/step-2' render={() => <Step2 />} />

            <Route path='/step-3' render={() => <Step3 />} />

            <Route path='/step-4' render={() => <Step4 />} />

            <Route path='/step-5' render={() => <Step5 />} />

            <Route path='/step-6' render={() => <Step6 />} />

            <Route path='/step-7' render={() => <Step7 />} />

            <Route path='/step-8' render={() => <Step8 />} />

            <Route path='/step-9' render={() => <Step9 />} />

            <Route path='/step-10' render={ () => <Step10 />} />

            <Route path='*' render={ () =>  <div>error 404</div>} />
          </Switch> 
        </div>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
