import React, { useState, useCallback, useContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button } from '../../Atoms/Buttons/Button';
import { ButtonLight } from '../../Atoms/Buttons/ButtonLight';
import { TextInput } from '../../Atoms/Inputs/Text';
import { TextTitles } from '../../Atoms/Texts/Titles';
import { useTranslation } from "react-i18next";
import s from "./Step4.module.scss"
import { useHistory } from "react-router-dom";
import { Api } from '../../../Services/Api'
import UserContext from "../../../context"

export const Step4 = () => {
    let history = useHistory();
    const { t } = useTranslation('common');
    const [smsCode, setSmsCode] = useState();
    const { id } = useContext(UserContext);

    const handleRepeat = useCallback(() => {
        Api().post('resendSMSCode', {
            id
        }).then(() => {
        }).catch((a) => {
            toast.error(a);
        }).finally()
    }, [id])

    const handleConfirm = useCallback(() => {
        Api().post('verifyPhone', {
            id,
            code: smsCode
        }).then((a) => {
            history.push("/step-9");
        }).catch((a) => {
            toast.error(a);
        }).finally()
    }, [smsCode, id, history])
    return (
        <div className={s.mainComponentBackground}>
            <ToastContainer />
            <div className={s.mainComponentBackgroundImage}>
                <div className={s.mainComponent}>
                    <div className={s.formBg}>
                        <div className={s.form}>
                            <TextTitles text={t('step4.title')} />
                            <TextInput
                                onChange={({ target }) => { setSmsCode(target.value) }}
                                placeholder={t('form.confirmationPlaceholder')}
                                label={t('form.confirmation')}
                                value={smsCode}
                            />
                            <div className={s.buttonWrapper}>
                                <div>
                                    <ButtonLight
                                        onClick={handleRepeat}
                                        value={t('button.repeat')}
                                    />
                                </div>
                                <div>
                                    <Button
                                        onClick={handleConfirm}
                                        value={t('button.confirm')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
