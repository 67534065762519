import React, { useContext, useState, useCallback } from 'react';
import { Button } from '../../Atoms/Buttons/Button';
import { ButtonLight } from '../../Atoms/Buttons/ButtonLight';
import { TextInput } from '../../Atoms/Inputs/Text';
import { TextTitles } from '../../Atoms/Texts/Titles';
import { Text16 } from '../../Atoms/Texts/Text16';
import { useTranslation } from "react-i18next";
import s from "./Step6.module.scss"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import UserContext from "../../../context";
import { Api } from '../../../Services/Api';

export const Step6 = () => {
    let history = useHistory();
    const {t} = useTranslation('common');
    const { id, email } = useContext(UserContext);
    const [ confirmation, setConfirmation ] = useState();

    const handleSubmit = useCallback(() => {
        Api().post('verifyEmail', {
            id,
            code: confirmation
        }).then((a) => {
            history.push("/step-7");
        }).catch((a) => {
            toast.error(a);
        }).finally()
    },[id, confirmation]);

    const handleRepeat = useCallback(() => {
        Api().post('resendEmail', {
            id,
            email
        }).then((a) => {
            toast.success("Successfuly updated");
        }).catch((a) => {
            toast.error(a);
        }).finally()
    },[id, confirmation]);

    return (
        <div className={s.mainComponentBackground}>
            <ToastContainer />
            <div className={s.mainComponentBackgroundImage}>
                <div className={s.mainComponent}>
                    <div className={s.formBg}>
                        <div className={s.form}>
                            <TextTitles
                                marginBottom={8}
                                text={t('step6.title')}
                            />
                            <Text16
                                text={t('step6.text')}
                            />
                            <TextInput
                                onChange={({target}) => { setConfirmation(target.value) }}
                                placeholder={t('form.confirmationPlaceholder')}
                                label={t('form.confirmation')}
                                marginBottom={12}
                                value={confirmation}
                            />
                            <div className={s.changeEmailWrapper}>
                                <span onClick={() => history.push("/step-10")} className={s.changeEmail}>{t('step6.changeEmail')}</span>
                            </div>
                            <div className={s.buttonWrapper}>
                                <div>
                                    <ButtonLight
                                        onClick={handleRepeat}
                                        value={t('button.repeat')}
                                    />
                                </div>
                                <div>
                                    <Button
                                        onClick={handleSubmit}
                                        value={t('button.confirm')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
