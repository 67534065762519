import React from "react";
import loader from './Subtract.png'
import s from './PreloaderWithText.module.scss'

export const PreloaderWithText = ({ text = "" }) => {
    return <div className={s.preloaderWrapper}>
        <div className={s.preloader}>
            <div className={s.preloaderText}>{text}</div>
            <img className={s.preloaderImage} src={loader} />
        </div>
    </div>
}