import React, {useState} from "react";
export const UserContext = React.createContext();


  export const UserProvider = ({ children }) => {
    const [currentData, setCurrentData] = useState(null);
    const [emailData, setEmailData] = useState(null);
  
    return (
      <UserContext.Provider
        value={{ id: currentData, setCurrentData, email: emailData, setEmailData }}
      >
        {children}
      </UserContext.Provider>
    );
  };
  
  export const UserConsumer = UserContext.Consumer;
  
  export default UserContext;