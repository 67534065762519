import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Button } from '../../Atoms/Buttons/Button';
import { CheckboxInput } from '../../Atoms/Inputs/Checkbox';
import { RadioInput } from '../../Atoms/Inputs/Radio';
import { RangeInput } from '../../Atoms/Inputs/Range';
import { SelectInput } from '../../Atoms/Inputs/Select';
import { TextInput } from '../../Atoms/Inputs/Text';
import { TextTitles } from '../../Atoms/Texts/Titles';
import { Api } from '../../../Services/Api'
import s from "./Login.module.scss"
import { useTranslation } from "react-i18next";
import UserContext from "../../../context"


export const Login = () => {
    let history = useHistory();
    const { t } = useTranslation('common');
    const { setCurrentData } = useContext(UserContext);
    const [ phone, setPhone ] = useState();
    const [ socialNumber, setSocialNumber ] = useState();
    const [ type, setType ] = useState();
    const [ agree, setAgree ] = useState();
    const [ credit, setCredit ] = useState(100000);
    const [ rangeInput, setRangeInput ] = useState();
    const [ year, setYear ] = useState('1');
    const [ coefficientValue, setCoefficientValue ] = useState();
    const [ eachMonthPaymentValue, setEachMonthPaymentValue ] = useState();

    const options = [
        { name: 'Choose', value: null },
        { name: 'Потребительский кредит', value: '1' },
        { name: 'Кредитная линия', value: '2' },
    ]

    const radioOptions = [
        { name: '12', value: '1', checked: "checked" },
        { name: '24', value: '2' },
        { name: '36', value: '3' },
        { name: '48', value: '4' },
        { name: '60', value: '5' },
    ]

    const handleSubmit = useCallback(() => {
        Api().post('login', {
            phone,
            socialNumber,
            type,
        }).then((a) => {
            setCurrentData(a.id);
            history.push("/step-4");
        }).catch((a) => {
            toast.error(a);
        }).finally()
    }, [phone, socialNumber, type, agree])

    useEffect(() => {
            const percentCoefficient = 15/100/12;
            let coefficient = (percentCoefficient*(Math.pow(1+percentCoefficient,year * 12)))/(Math.pow(1+percentCoefficient,year * 12) -1)
            let eachMonthPayment = credit*coefficient;
            setCoefficientValue(coefficient);
            setEachMonthPaymentValue(eachMonthPayment);
    }, [year, rangeInput, credit]);
   
    return (
        <div className={s.mainComponentBackground}>
            <ToastContainer />
            <div className={s.mainComponentBackgroundImage}>
                <div className={s.mainComponent}>
                    <div className={s.formBg}>
                        <div className={s.formTitle}>
                            {t('login.title')}
                        </div>
                        <div className={s.form}>
                            <div className={s.formLeft}>
                                <TextTitles text={t('login.requestTitle')} dark={false} />
                                <TextInput
                                    dark={false}
                                    onChange={({ target }) => { setPhone(target.value) }}
                                    placeholder={t('form.phonePlaceholder')}
                                    label={t('form.phone')}
                                    value={phone}
                                />
                                <TextInput
                                    dark={false}
                                    onChange={({ target }) => { setSocialNumber(target.value) }}
                                    placeholder={t('form.socCardPlaceholder')}
                                    label={t('form.socCard')}
                                    value={socialNumber}
                                />
                                <SelectInput
                                    dark={false}
                                    onChange={({ target }) => { setType(target.value) }}
                                    options={options}
                                />
                                <CheckboxInput
                                    dark={false}
                                    onChange={({ target }) => { setAgree(target.value) }}
                                    label={t('login.condition')}
                                />
                                <Button
                                    onClick={handleSubmit}
                                    value={t('button.buttonName')}
                                    disabled={!(agree && phone && socialNumber && type)}
                                />
                            </div>
                            <div className={s.formRight}>
                                <TextTitles marginBottom={0} text={t('login.calculatorTitle')} dark={true} />
                                <div className={s.formRightText}>
                                    {t('login.calculatorText')}
                                </div>

                                <TextInput
                                    onChange={({target}) => { 
                                        setCredit(target.value) 
                                        setRangeInput(target.value)
                                    }}
                                    placeholder={t('form.credit')}
                                    label={t('form.credit')}
                                    value={credit}
                                />

                                <RangeInput
                                    min={100000}
                                    max={5000000}
                                    value={rangeInput}
                                    onChange={({target}) => { 
                                        setRangeInput(target.value)
                                        setCredit(target.value)
                                    }}
                                />

                                <RadioInput
                                    label={t('form.termCredit')}
                                    options={radioOptions}
                                    onChange={({target}) => { 
                                        setYear(target.value) 
                                    }}
                                />

                                <div className={s.formOffer}>
                                    <TextTitles marginBottom={0} text={t('login.offer')} dark={true} />
                                    <div className={s.formOfferType}>
                                        <div className={s.formOfferLeft}>
                                            <div className={s.formOfferSubText}>{t('login.monthlyPayment')}</div>
                                            <div className={s.formOfferText}>{eachMonthPaymentValue?.toFixed(2)} {t('global.currency')}</div>
                                        </div>
                                        <div className={s.formOfferRight}>
                                            <div className={s.formOfferSubText}>{t('login.InterestRate')}</div>
                                            <div className={s.formOfferText}>15 %</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
