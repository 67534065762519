import React from "react";
import s from './Radio.module.scss'

export const RadioInput = ({onChange = () => {}, placeholder="", value="", error, label="", dark=true, options=[] }) => {
    return  <div className={s.formYears}>
                <span className={s.formYearsTitle}>{label}</span>
                <div className={s.formYearsinputs}>
                    {options.map((item, index) => {
                        return (
                            <div key={index}>
                                <input defaultChecked={item.checked} onChange={onChange} type="radio" id={index} name="year" value={item.value} />
                                <label htmlFor={index}>{item.name}</label>
                            </div>
                        )
                    })}
                </div>
            </div>
}