export function Api() {
    const prefix = process.env.REACT_APP_API_URL;
    const post = (url, body) => {
        return fetch(prefix + url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        }).then(async (data) => {
            if (data.status === 200) {
                return data.json()
            }
            const text = await data.text()
            throw text
        })
    }

    const get = (url, body) => {
        return fetch(prefix + url, {
            method: 'GET', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        }).then(async (data) => {
            if (data.status === 200) {
                return data.json()
            }
            const text = await data.text()
            throw text
        })
    }

    const put = (url, body) => {
        return fetch(prefix + url, {
            method: 'PUT', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        }).then(async (data) => {
            if (data.status === 200) {
                return data.json()
            }
            const text = await data.text()
            throw text
        })
    }

    return {
        get,
        post,
        put
    }
}