import React from 'react';
import { Button } from '../../Atoms/Buttons/Button';
import { CheckboxInput } from '../../Atoms/Inputs/Checkbox';
import { RadioInput } from '../../Atoms/Inputs/Radio';
import { RangeInput } from '../../Atoms/Inputs/Range';
import { SelectInput } from '../../Atoms/Inputs/Select';
import { TextInput } from '../../Atoms/Inputs/Text';
import { TextTitles } from '../../Atoms/Texts/Titles';
import { TopNotification } from '../../Atoms/Notification/TopNotification';
import { useTranslation } from "react-i18next";
import s from "./Step3.module.scss"

export const Step3 = () => {
    const { t } = useTranslation('common');
    const options = [
        { name: 'Choose', value: null },
        { name: 'Потребительский кредит', value: '1' },
        { name: 'Кредитная линия', value: '2' },
    ]

    const radioOptions = [
        { name: '12', value: '1' },
        { name: '24', value: '2' },
        { name: '36', value: '3' },
        { name: '48', value: '4' },
        { name: '60', value: '5' },
    ]
    return (
        <div className={s.mainComponentBackground}>
            <div className={s.mainComponentBackgroundImage}>
                <div className={s.mainComponent}>
                    <div className={s.formBg}>
                        <div className={s.topNotificationSection}>
                            <TopNotification
                                text={t('step3.notification')}
                                messageHandler={false}
                            />
                        </div>
                        <div className={s.form}>

                            <div className={s.formLeft}>
                                <TextTitles text={t('step3.requestTitle')} dark={false} />
                                <TextInput
                                    dark={false}
                                    onChange={(event) => { console.log(event) }}
                                    placeholder={t('form.phonePlaceholder')}
                                    label={t('form.phone')}
                                />
                                <TextInput
                                    dark={false}
                                    onChange={(event) => { console.log(event) }}
                                    placeholder={t('form.socCardPlaceholder')}
                                    label={t('form.socCard')}
                                />
                                <SelectInput
                                    dark={false}
                                    onChange={(event) => { console.log(event) }}
                                    options={options}
                                />
                                <CheckboxInput
                                    dark={false}
                                    onChange={(event) => { console.log(event) }}
                                    label={t('step3.condition')}
                                />
                                <Button
                                    onClick={(event) => { console.log(event) }}
                                    value={t('button.buttonName')}
                                />
                            </div>
                            <div className={s.formRight}>
                                <TextTitles text={t('step3.calculatorTitle')} dark={true} />
                                <div className={s.formRightText}>
                                    {t('step3.calculatorText')}
                                </div>

                                <TextInput
                                    onChange={(event) => { console.log(event) }}
                                    placeholder={t('form.credit')}
                                    label={t('form.credit')}
                                />

                                <RangeInput
                                    min={30000}
                                    max={5000000}
                                    onChange={(event) => { console.log(event) }}
                                />

                                <RadioInput
                                    label={t('form.termCredit')}
                                    options={radioOptions}
                                    onChange={(event) => { console.log(event) }}
                                />

                                <div className={s.formOffer}>
                                    <TextTitles text={t('step3.offer')} dark={true} />
                                    <div className={s.formOfferType}>
                                        <div className={s.formOfferLeft}>
                                            <div className={s.formOfferSubText}>{t('step3.monthlyPayment')}</div>
                                            <div className={s.formOfferText}>2640 {t('global.currency')}</div>
                                        </div>
                                        <div className={s.formOfferRight}>
                                            <div className={s.formOfferSubText}>{t('step3.InterestRate')}</div>
                                            <div className={s.formOfferText}>5,5 %</div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
