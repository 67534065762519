import React from "react";
import file from "./attach_file.png"
import s from './ChooseFile.module.scss'

export const ChooseFileInput = ({onChange = () => {}, placeholder="", value="", error, label="", dark=true }) => {
   return <div className={s.ChooseFileWrapper}>
            <label for="avatar"><img src={file} />Прикрепить файл</label>
            <input type="file" onChange={onChange}
                id="avatar" name="avatar"/>
        </div> 
}