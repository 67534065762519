import React, {useContext, useState, useCallback} from 'react';
import { Button } from '../../Atoms/Buttons/Button';
import { ButtonLight } from '../../Atoms/Buttons/ButtonLight';
import { TextInput } from '../../Atoms/Inputs/Text';
import { TextTitles } from '../../Atoms/Texts/Titles';
import { useTranslation } from "react-i18next";
import s from "./Step10.module.scss"
import UserContext from "../../../context"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Api} from '../../../Services/Api'
import { useHistory } from "react-router-dom";

export const Step10 = () => {
    const { t } = useTranslation('common');
    const { id, email } = useContext(UserContext);
    const [newEmail, setNewEmail] = useState('');
    let history = useHistory();

    const handleSubmit = useCallback(() => {
        Api().post('updateEmail', {
            id,
            email: newEmail
        }).then((a) => {
            toast.success("Successfuly Changed");
        }).catch((a) => {
            toast.error(a);
        }).finally()
    },[id, newEmail]);

    return (
        <div className={s.mainComponentBackground}>
            <ToastContainer />
            <div className={s.mainComponentBackgroundImage}>
                <div className={s.mainComponent}>
                    <div className={s.formBg}>
                        <div className={s.form}>
                            <TextTitles
                                marginBottom={0}
                                text={t('step10.title')}
                            />
                            <div className={s.currentEmailWrapper}>
                                <div className={s.currentEmaiTitle}>
                                    {t('step10.currentEmaiTitle')}
                                </div>
                                <div className={s.currentEmaiText}>
                                    {email}
                                </div>
                            </div>
                            <TextInput
                                onChange={({target}) => { setNewEmail(target.value) }}
                                placeholder={t('form.email')}
                                label={t('form.newEmail')}
                                marginBottom={12}
                                value={newEmail}
                            />
                            <div className={s.buttonWrapper}>
                                <div>
                                    <ButtonLight
                                        onClick={() => history.push("/step-6")}
                                        value={t('button.cancel')}
                                    />
                                </div>
                                <div>
                                    <Button
                                        onClick={handleSubmit}
                                        value={t('button.confirm')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
