import React from "react";
import s from './SelectWithLabel.module.scss'

export const SelectWithLabelInput = ({onChange = () => {}, placeholder="", value="", error, label="", dark=true, options=[] }) => {
    return <label className={`${dark? s.darkFormSelect : s.lightFormSelect} ${s.formSelect}`}>
        {label}
        <select onChange={onChange} >
            {options.map((item, index) => {
                return <option key={index} value={item.Value}>{item.Name}</option>
            })}
        </select>
    </label>
}