import React from "react";
import close from './close.png';
import {Button} from '../../Buttons/Button';
import {Line} from '../../Lines/Line';
import s from './PopupWithText.module.scss';

export const PopupWithText = ({title="", text="", onSubmit, onClose}) => {
    return <div className={s.popupWrapper}>
                <div className={s.popupBox}>
                    <div className={s.popupHeader}>
                        <img onClick={onClose} className={s.preloaderImage} src={close} />
                    </div>
                    <div className={s.popupBody}>
                        <div className={s.popupBodyTitle}>{title}</div>
                        <Line marginBottom={36} />
                        <div className={s.popupBodyText}>{text}</div>
                    </div>
                    <div className={s.popupFooter}>
                        <Button
                            onClick={onSubmit}  
                            value="Оформить"
                        />
                    </div>
                </div>
            </div>
}