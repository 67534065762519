import React, {useState, useCallback, useContext} from 'react';
import { Button } from '../../Atoms/Buttons/Button';
import { TextInput } from '../../Atoms/Inputs/Text';
import { SelectWithLabelInput } from '../../Atoms/Inputs/SelectWithLabel';
import { CheckboxInput } from '../../Atoms/Inputs/Checkbox';
import { TextTitles } from '../../Atoms/Texts/Titles';
import { DateInput } from '../../Atoms/Inputs/Date';
import s from "./Step7.module.scss";
import { PreloaderWithText } from '../../Atoms/Preloader/PreloaderWithText';
import {useTranslation} from "react-i18next";
import { RangeInput } from '../../Atoms/Inputs/Range';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Api } from '../../../Services/Api';
import UserContext from "../../../context";
import { useHistory } from "react-router-dom";

const options1 = [
    {Name: 'xxxxxxxxxx', Value: 'xxxxxxxxxx'},
    {Name: 'Loan Type2', Value: 'Loan Type2'},
    {Name: 'Loan Type3', Value: 'Loan Type3'},
    {Name: 'Loan Type4', Value: 'Loan Type4'},
]

const isLoading = false;

export const Step7 = () => {
    let history = useHistory();
    const [date, setDate] = useState();
    const [creditCard, setCreditCard] = useState();
    const [takeTheCard, setTakeTheCard] = useState();
    const [newCard, setNewCard] = useState();
    const [insurance, setInsurance] = useState();
    const [agree, setAgree] = useState();
    const [cardType, setCardType] = useState();

    const { id } = useContext(UserContext);
    
    const {t} = useTranslation('common');

    const handleSubmit = useCallback(() => {
        history.push("/step-8");
    },[id]);

    return (
        <div className={s.mainComponentBackground}>
             <ToastContainer />
            {isLoading && <PreloaderWithText text="Lorem Ipsum has been make a the industry's standard dummy text ever since the 1500s, took a galley of type and scrambled it to make a type specimen book." />}
            <div className={s.mainComponentBackgroundImage}>
                <div className={s.mainComponent}>
                    <div className={s.formBg}>
                        <div className={s.form}>
                            <TextTitles text={t('step7.title')} />
                            <div className={`${s.inputWrapper} ${s.firstWrapper}`}>
                                <div className={s.rangeForm}>
                                    <TextInput
                                        onChange={(event) => { console.log(event) }}
                                        placeholder={t('form.selectLoanAmount')}
                                        label={t('form.selectLoanAmount')}
                                    />

                                    <RangeInput
                                        min={30000}
                                        max={5000000}
                                        onChange={(event) => { console.log(event) }}
                                    />

                                </div>
                                <div className={s.lineTextWrapper}>
                                    <div className={s.lineText}>
                                        <div className={s.lineTextTitle}>
                                        {t('step7.creditAmount')}
                                        </div>
                                        <div className={s.lineTextText}>
                                        5.000.000 {t('global.currency')}
                                        </div>
                                    </div>
                                    <div className={s.lineText}>
                                        <div className={s.lineTextTitle}>
                                        {t('step7.monthlyFee')}
                                        </div>
                                        <div className={s.lineTextText}>
                                        20500 {t('global.currency')}
                                        </div>
                                    </div>
                                    <div className={s.lineText}>
                                        <div className={s.lineTextTitle}>
                                        {t('step7.creditTerm')}
                                        </div>
                                        <div className={s.lineTextText}>
                                        60 {t('global.month')}
                                        </div>
                                    </div>
                                    <div className={s.lineText}>
                                        <div className={s.lineTextTitle}>
                                        {t('step7.interestRate')}
                                        </div>
                                        <div className={s.lineTextText}>
                                        14.6%
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className={s.inputWrapper}>
                                <DateInput 
                                    onChange={({target}) => {setDate(target.value)}} 
                                    label={t('form.dateOfNextRedemption')} 
                                />
                                <TextInput 
                                    onChange={({target}) => {setCreditCard(target.value)}} 
                                    placeholder={t('form.applyForCreditCardPlaceholder')}  
                                    label={t('form.applyForCreditCard')} 
                                    value={creditCard}
                                />
                            </div>
                            <div className={s.inputWrapper}>
                                <TextInput 
                                    onChange={({target}) => {setTakeTheCard(target.value)}} 
                                    placeholder={t('form.branchFromWhereIWillTakeTheCardPlaceholder')} 
                                    label={t('form.branchFromWhereIWillTakeTheCard')}  
                                    value={takeTheCard}
                                />
                                <div className={s.emptyBlock}></div>
                            </div>
                            <CheckboxInput
                                onChange={({target}) => {setNewCard(target.value)}} 
                                label={t('form.issueANewCard')}  
                            />
                            <CheckboxInput
                                onChange={({target}) => {setInsurance(target.value)}} 
                                label={t('form.insurance')}  
                            />
                            <div className={s.inputWrapper}>
                                <SelectWithLabelInput 
                                    onChange={({target}) => {setCardType(target.value)}} 
                                    options={options1}
                                    label={t('form.selectCardType')}  
                                />
                                <div className={s.emptyBlock}></div>
                            </div>
                            <CheckboxInput
                                onChange={({target}) => {setAgree(target.value)}} 
                                label={t('form.agreeToOpenTerms')}   
                            />
                            <Button
                                onClick={handleSubmit}  
                                value={t('button.buttonName')} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
